<template>
	<div class="c-forgot-password">
		<div class="o-container o-container--full">
			<div class="grid">
				<div class="row">
					<div class="col-md-6">
						<div class="c-forgot-password__left">
							<div class="c-forgot-password__switch">
								Ben je je wachtwoord niet vergeten?
								<router-link :to="{ name: 'Login' }" title="Create account"> Log In </router-link>
							</div>
							<div class="c-forgot-password__form">
								<h1>Wachtwoord vergeten</h1>
								<p>
									Voer uw e-mailadres in en wij sturen u de instructies voor het resetten van uw
									wachtwoord.
								</p>
								<InputComponent
									v-model="email"
									input-type="email"
									label="E-mailadres"
									autocomplete="email"
								/>

								<button class="c-button" @click="requestNewPassword()">Instructies versturen</button>
							</div>
						</div>
					</div>
					<div class="col-md-6 u-tablet-hide">
						<div class="c-forgot-password__right">
							<div class="c-forgot-password__logo">
								<img src="~@/assets/images/logo-blue.svg" alt="" />
							</div>
							<div class="c-forgot-password__visual">
								<img src="~@/assets/images/login-visual.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./ForgotPassword.controller.js"></script>
<style lang="scss" src="./ForgotPassword.styles.scss"></style>
